import { useState, useEffect, useCallback } from "react";
import * as _ from "underscore";

export const useSelection = (initialItems = []) => {
    const [selectMap, setSelectMap] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);

    const selectAllItems = useCallback((selected: boolean, items) => {
        if (selected) {
            setSelectedItems([...items]);
        } else {
            setSelectedItems([]);
        }
    }, []);

    useEffect(() => {
        if (isAllSelected) {
            selectAllItems(true, initialItems);
        } else {
            //setSelectedItems([]);
        }
    }, [isAllSelected, initialItems, selectAllItems]);

    const selectItem = useCallback((item, selected: boolean) => {
        setSelectedItems((prevSelectedItems) => {
            if (selected) {
                return [...prevSelectedItems, item];
            } else {
                return _.reject(prevSelectedItems, (i) => i.id === item.id);
            }
        });
    }, []);

    useEffect(() => {
        const mapKeys = _.pluck(selectedItems, "id");
        setSelectMap(_.object(mapKeys, mapKeys.map(() => true)));
    }, [selectedItems]);

    return {
        selectMap,
        selectedItems,
        isAllSelected,
        setIsAllSelected,
        selectItem,
    };
};
