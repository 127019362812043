import React, {Fragment, useEffect, useState} from "react";
import {Button, Col, Divider, Form, Row, Input, Table, useModal, useForm, Create} from "@pankod/refine-antd";
import {IResourceComponentsProps} from "@pankod/refine-core";
import {IProductBundle, Product} from "Interfaces";
import {ProductModal} from "./Components/ProductModal";
import {CodeFormItem, NameFormItem} from "./Components/FormItems";
import {PublishedProductStatusIcon} from "../Products/PublishedProductStatusIcon";

export const ProductBundleCreate: React.FC<IResourceComponentsProps> = () => {
    const {show: showProductModal, close: closeProductModal, modalProps} = useModal();
    const {formProps, saveButtonProps, form} = useForm<IProductBundle>({
        resource: "product-bundles",
        action: "create",
    });

    const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
    const [quantities, setQuantities] = useState<{ [key: string]: number }>({});

    useEffect(() => {
        form.setFieldsValue({products: selectedProducts.map(product => product.id)});
    }, [selectedProducts, form]);

    const handleQuantityChange = (sku: string, value: number) => {
        const newQuantities = {
            ...quantities,
            [sku]: value,
        };
        setQuantities(newQuantities);
        form.setFieldsValue({quantityTable: newQuantities});
    };

    return (
        <Create saveButtonProps={saveButtonProps}>
            <ProductModal
                modalProps={modalProps}
                closeProductModal={closeProductModal}
                setSelectedIds={setSelectedProducts}
            />

            <Form {...formProps} layout="vertical">
                <Row gutter={{xs: 8, sm: 16, md: 24, lg: 32}}>
                    <Col>
                        <NameFormItem/>
                    </Col>
                    <Col>
                        <CodeFormItem/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Divider/>
                        <Table dataSource={selectedProducts} rowKey="id" pagination={false}>
                            <Table.Column title="SKU" dataIndex="masterSku" key="masterSku"/>
                            <Table.Column title="Name" key="name"
                                          render = {(_, record: Product) => (
                                              <PublishedProductStatusIcon productProp={record}/>
                                          )}
                            />
                            <Table.Column
                                title="Quantity Available"
                                key="quantityAvailable"
                                render={(_, record: { masterSku: string }) => (
                                    <Input
                                        type="number"
                                        value={quantities[record.masterSku] || 1}
                                        onChange={(e) => handleQuantityChange(record.masterSku, Number(e.target.value))}
                                    />
                                )}
                            />
                        </Table>
                        <Divider/>
                        <Button type="default" onClick={showProductModal}>
                            Assign Products
                        </Button>
                    </Col>
                </Row>
                <Form.Item name="products" hidden>
                    <input type="hidden"/>
                </Form.Item>
                <Form.Item name="quantityTable" hidden>
                    <input type="hidden"/>
                </Form.Item>
            </Form>
        </Create>
    );
};
